$color-primary: #FF6600;
$color-primary-dark: #38383A;

$color-secondary-dark: #252527;

$color-red: #f44336;
$color-yellow: #ffeb3b;
$color-grey: #ccc;
$color-grey-sub: #f9f9f9;
$color-green: #4CAF50;
$color-dark-white: rgba(255, 255, 255, 0.87);
$color-dark-white-pure: rgba(255, 255, 255, 0.7);

$shadow-5: 0 5px 10px rgba(0,0,0,0.4), 0 5px 10px rgba(0,0,0,0.4);
$shadow-4: 0 4px 10px rgba(0,0,0,0.4), 0 4px 10px rgba(0,0,0,0.4);
$shadow-2: 0 2px 10px rgba(0,0,0,0.4), 0 2px 10px rgba(0,0,0,0.4);

$chevron: polygon(100% 0, 100% 100%, 0% 100%, 25% 50%, 0% 0%);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');

.vl-team {
  flex: 1;

  p, h1, h2, h3, h4, h5 {
    margin: 0;
    line-height: 1.16;
  }

  h1, .vl-h1 {
    font-size: 38px
  }
  h2, .vl-h2 {
    font-size: 24px;
    line-height: 36px;
  }

  .container {
    margin: 0 auto;
    max-width: 1140px;
  }

  .main {
    min-height: initial !important;

    .header-container {
      margin: 0 16px 8px;
    }

    .description-wrapper {
      display: flex;
      align-items: center;

      .vl-social-decor {
        width: 300px;
        margin-top: 60px;
      }
      .vl-social-decor-right {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

      .description {
        max-width: 960px;
        margin: 8px auto 36px;
        flex: 1;

        .vl-social {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          a {
            border-radius: 50px;
          }
          .vl-social-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #4d4d4d;
          }
        }
      }

    }

    .team {
      padding: 36px 0;
      color: $color-dark-white;
      position: relative;

      h1 {
        color: $color-dark-white !important;
      }

      .team-container {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .swipe {
          max-width: 80%;
          >div>div{
            height: auto !important;
          }
        }

        .arrow {
          width: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          cursor:pointer;
        }
        .arrow:hover {
          background: rgba(255, 255, 255, 0.1);
        }
        .arrow.right {
          transform: rotate(180deg)
        }

        .vl-swipe-pagination {
          max-width: 1024px;
        }

        .member-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          max-width: 1024px;
          margin: 0 auto;

          .card {
            display: flex;
            flex-direction: column;
            min-height: 400px;
            max-width: 280px;
            justify-content: flex-start;
            align-items: center;
            text-align: center;

            .image {
              border-radius: 50%;
              background-color: white;
              height: 120px;
              width: 120px;
              display: flex;
              align-items: center;
              margin: 0 auto;
              div {
                width: 106px;
                height: 106px;
                margin: 0 auto;
                border-radius: 100px;
                background-size: auto 100%;
                background-position: 50%;
                background-position-y: 20%;
              }
              .size-90 {
                width: 90px;
                height: 90px;
              }
            }

            .name {
              font-size: 24px;
              font-weight: 400;
            }

            .title {
              font-size: 16px;
              line-height: 1.5;
              white-space: pre;
            }

            .card-description {
              line-height: 1;
              font-size: 14px;
              text-align: center;
              min-height: 105px;
              a {
                color: $color-dark-white;
                font-weight: 400;
              }
              >span {
                font-weight: 400;
                font-size: 15px;
              }
            }

            .card-show-more {
              cursor: pointer;
            }

            .social {
              width: 100%;
              display: flex;
              justify-content: center;
              a {
                color: $color-dark-white;
              }
            }
          }

        }
        ::-webkit-scrollbar {
          width: 0px;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }

      }

    }

  }

  @media (max-width: 860px) {
    .main {
      .header-container {
        height: 60px;
      }
      .team-container {
        max-height: initial;
        .member-container {
          height: initial;

        }
      }
      .vl-social-decor {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 0;

    .vl-social {
      flex-wrap: wrap;
    }

    .main {
    }

    .description {
      margin: 0;
    }

    .main .team .team-container {
      .arrow {
        display: none;
      }
      .swipe {
        max-width: 100%;
        .member-container {
          max-width: 250px;
        }
      }
    }
  }

}