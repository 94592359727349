$color-primary: #ff6600;
$color-primary-dark: #38383A;

$color-secondary-dark: #252527;

$color-red: #f44336;
$color-yellow: #ffeb3b;
$color-grey: #ccc;
$color-grey-sub: #f9f9f9;
$color-green: #4CAF50;
$color-dark-white: rgba(255, 255, 255, 0.87);
$color-dark-white-pure: rgba(255, 255, 255, 0.7);

$shadow-5: 0 5px 10px rgba(0,0,0,0.16), 0 5px 10px rgba(0,0,0,0.23);
$shadow-2: 0 2px 10px rgba(0,0,0,0.16), 0 2px 10px rgba(0,0,0,0.23);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');

.vulners-landing-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.landing-container { //TODO
  .vulners-search-panel {
    background: $color-primary-dark;
    box-shadow: none;
  }
}

.vl-landing {

  h1, .vl-h1 {
    font-size: 38px;
    font-weight: 300;
  }
  h2, .vl-h2 {
    font-size: 24px;
    font-weight: 300;
  }
  h3, .vl-h3 {
    font-size: 24px;
    font-weight: 200;
  }
  h4, .vl-h4 {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
  }
  p, li, .vl-p {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 300;
    word-wrap: break-word;
  }

  .container {
    margin: 0 auto;
    max-width: 1140px;
  }

  button {
    min-width: initial !important;
    span {
      color: #FF6600;
      padding: 0 !important;
    }
  }

  .cube {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 150px;
    width: 135px;
    background: url('/static/img/landing/cube.svg') no-repeat center;
    svg {
      height: 100% !important;
      width: 75px !important;
    }
  }

  .header {
    h1:nth-child(1) {
      text-align: right;
    }
    h1:nth-child(2) {
      text-align: left;
    }
    .cube {
      height: 125px;
      width: 110px;
      background-size: 90%;
      svg {
        width: 60px !important;
      }
    }
  }

  .main {
    color: $color-dark-white;
    background-color: $color-primary-dark;
    text-align: center;
    padding: 120px 0 0;
    position: relative;

    h1 {
      height: 60px;
    }

    .numbers {
      position: absolute;
      color: #FF660010;
      bottom: 0;
      top: 0;
      user-select: none;
      background: linear-gradient(#ff660020, #38383a);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: monospace;
    }

    .call-to-action-container {
      position: relative;
    }
    .call-to-action {
      display: flex;
      white-space: pre-wrap;
      justify-content: space-around;

      >div {
        .button {
          border-color: $color-primary;
          span {
            color: white
          }
        }
      }
    }

  }

  .solutions {
    color: $color-dark-white;
    background-color: $color-primary-dark;
    padding-top: 60px;

    h1 {
      padding: 48px;
      text-align: center;
    }
    h3 {
      text-align: center;
      margin: 15px auto;
    }
    p {
      color: $color-dark-white-pure;
      margin-bottom: 30px;
      padding-left: 18px;
    }
    a {
      color: $color-dark-white;
      text-decoration: initial;
    }

    .logo-right-container {
      height: 0;
      top: -50px;
      position: relative;
    }
    .logo-right {
      height: 200px;
      position: absolute;
      right: 0;
    }

    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding-bottom: 50px;
      .card {
        width: 300px;
        display: table;
        margin: 0 15px 15px;
        .cube {
          margin: 0 auto;
        }
      }
      .phantom-1 {
        width: 300px;
        height: 1px;
        margin: 0 25px;
      }
      .phantom-2 {
        display: none;
        width: 300px;
        height: 1px;
        margin: 0 25px;
      }
    }

    .statistic-container {
      height: 0;
      position: relative;
      bottom: 296px;
    }
    .statistic {
      background: url('/static/img/landing/cube-black.svg') no-repeat center;
      background-size: 100% 100%;
      max-width: 320px;
      max-height: 340px;
      padding: 50px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .cve {
        margin: 25px auto;
      }
      img, > div {
        display: flex;
        margin: 10px auto;
      }
      h2 {
        margin: 0 5px;
        font-size: 30px;
        font-weight: 500;
        color: $color-primary;
      }
      h3 {
        margin: 0 5px;
        line-height: 1.36;
        color: $color-dark-white;
      }
    }
  }




  .search {
    background-color: $color-grey-sub;


    .header {
      padding-top: 48px;
      display: flex;
      align-content: center;
      justify-content: center;

      h1 {
        margin-right: 64px;
        text-align: center;
        align-self: center;
      }
    }

    .search-container {

      display: flex;
      align-content: center;
      justify-content: space-between;

      .vendors {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .search {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 810px;
        background-color: $color-dark-white;
        margin-bottom: 50px;
        .search-bar > div > div:nth-child(1) {
          margin: 36px;
          padding: 0 10px 0 14px;
          border-radius: 50px;
          border: $color-grey 1px solid;
        }
        .search-bar > div > div:nth-child(2) {
          margin: 0 58px;
        }
      }
      .text {
        h2 {
          margin: 15px 0;
        }
        button {
          float: right;
        }
        max-width: 350px;
        padding: 50px 0 50px 50px;
        display: flex;
        flex-direction: column ;
      }
    }
  }


  .risks {
    background-color: $color-primary-dark;

    padding: 15px 0;

    h1 {
      text-align: center;
    }
    h2 {
      margin: 15px 0;
    }

    .header {
      padding: 48px;
      display: flex;
      align-content: center;
      justify-content: center;

      h1 {
        align-self: center;
        padding: 0 36px;
        color: $color-dark-white;
      }
    }
    .container {
      display: flex;
    }
    .text {
      color: $color-dark-white;
      max-width: 300px;
      flex-grow: 1;
      flex-shrink: 0;
      p {
        color: $color-dark-white-pure
      }
    }
    .card-container-scroll {
      height: 400px;
      overflow: hidden;
      .card-container {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 20px;
        a {
          text-decoration: none;
        }
        .card {
          width: 230px;
          height: 388px;
          margin: 0 15px;
          h1 {
            padding: 30px 0;
            width: 100%;
            height: 190px;
            vertical-align: middle;
          }
          .card-media {
            height: 170px;
          }
        }
        .card:hover {
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
      }
    }
  }

  .audit {
    color: $color-dark-white;
    background-size: auto 120%;
    background: $color-primary-dark url('/static/img/background.jpg');

    .header {
      h1 {
        padding: 0 36px;
      }
      padding: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .subheader {
      text-align: center;
    }

    .container {
      display: flex;
      margin: 30px auto;
      h2 {
        margin: 15px 0;
      }
      .card {
        max-width: 350px;
        margin: 0 10px;
        .card-image {
          height: 250px;
          width: 230px;
        }
        .appscan {
          background-size: 50% auto;
          background-image: url("/static/img/landing/appscan.svg");
          background-repeat:  no-repeat;
          background-position: 50%;
        }
        .cloud {
          background-size: 100% auto;
          background-image: url("/static/img/landing/cloud.svg");
          background-repeat:  no-repeat;
          background-position: 50%;
        }
        .standalone {
          background-size: 60% auto;
          background-image: url("/static/img/landing/standalone.svg");
          background-repeat:  no-repeat;
          background-position: 50%;
        }
      }
    }

  }


  .api {
    background-color: $color-grey-sub;
    padding: 30px 0 50px;

    h2 {
      margin: 15px 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        padding-left: 70px;
      }

      .arrows {
        width: 200px;
        height: 60px;
        background-size: 100% auto;
        background-image: url("/static/img/landing/arrows-next.svg");
        background-repeat:  no-repeat;
        background-position: 50%;
      }
      .scanners {
        display: flex;
        justify-content: space-around;
        img {
          margin: 0 5px;
        }
      }
    }
  }


  .ai {
    background-color: $color-grey-sub;
    padding: 30px 0;

    h2 {
      margin: 15px 0;
    }

    img {
      background-color: transparent !important;
    }

    .container {
      display: flex;
      align-items: center;

      background: url("/static/img/chip.svg") no-repeat 103% 40%;
      background-size: 30%;

      .cube {
        align-items: center;
      }
      .text {
        padding-left: 70px;
      }
    }
  }


  .final {
    background-color: $color-secondary-dark;
    padding: 30px;
    text-align: center;
  }

  .vulners-simple-footer-bar {
    margin-top: 0;
    padding-top: 30px;
  }


  @media (max-width: 1140px) {

    .container {
      padding: 0 36px;
    }

    .main {
      .header-container {
        margin-top: 50px;
        > div {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;
        }
        .header-text {
          display: block;
        }
        .logo-large {
          margin-top: 0;
          float: initial;
        }
      }
    }

  }


  @media (max-width: 1500px) {
    .main {
      background-size: auto 110%;
    }
  }

  @media (max-width: 1120px) {
    .solutions {
      .card-container {
        justify-content: space-around;
        .phantom-1 {
          display: none !important;
        }
        .phantom-2 {
          display: block;
        }
      }
    }
    .ai .container {
        background-position: 103% 10%;
    }
  }

  @media (max-width: 960px) {
    .main .header-container .logo-large {
      margin-top: 0;
    }
    .search .header h1 {
      font-size: 24px;
      margin-right: 8px;
      text-align: right;
    }
    .api .container .text {
      padding: 0 10px;
    }
  }

  @media (max-width: 783px) {

    .header {
      h1 {
        font-size: 24px;
      }
    }

    .main {
      .header-container {
        margin-top: 60px;
        .logo-large {
          float: right;
        }
      }
    }

    .solutions {
      .card-container {
        justify-content: space-around;
        .card {
          margin: 0;
        }
      }
      .statistic-container {
        left: -30px;
      }
    }
    .search .search-container {
      display: block;
      .header {
        padding-bottom: 8px;
      }
      .search {
        height: 218px;
        margin-bottom: 0;
        box-shadow: 0 0 0 0;
      }
      .text {
        max-width: initial;
        padding: 30px;
      }
    }
    .risks .header, .audit .header {
      padding: 24px;
    }
    .api {
      padding: 0 0 30px;
    }
    .customers .container {
      img {
        margin: 5px 0;
      }
    }

    .audit .container {
      flex-direction: column;
      .card {
        max-width: initial;
        display: flex;
        .card-image {
          height: 200px;
          width: 200px;
          flex-shrink: 0;
          margin-right: 30px;
        }
        button {
          float: right;
        }
      }
    }

  }


  
  @media (max-width: 700px) {
    .container {
      padding: 0 18px;
    }
    .solutions {
      .logo-right {
        display: none;
      }
      .phantom-2 {
        display: none!important;
      }
      .card-container {
        padding-bottom: 0;
      }
      .statistic-container {
        height: initial;
        position: initial;
        bottom: 296px;
        display: flex;
        justify-content: center;
      }
    }
    .search .header {
      padding: 24px;
      justify-content: center;
      flex-direction: row-reverse;
      h1 {
        text-align: left;
        margin-left: 18px;
      }
    }
    .api .container {
      .arrows, .scanners {
        display: none;
      }
    }
    .ai {
      .container {
        background: none;
      }
      .text {
        padding-left: 10px!important;
      }
    }
    .risks{
      .container {
        display: block;
        flex-wrap: wrap;
        .text {
          max-width: initial;
        }
      }
    }
  }


  @media (max-width: 471px) {
    .header {
      flex-direction: column;
      h1, h1:nth-child(1), h1:nth-child(2) {
        text-align: center;
      }
      .cube {
        margin: 0 auto;
      }
    }

    .main {
      background-size: auto 140%;
      h1 {
        height: initial;
      }
      .header-container {
        .logo-large {
          max-height: 60px;
        }
        .header-text {
          font-size: 32px
        }
      }
      .call-to-action {
        flex-direction: column;
        >div{
          margin-bottom: 48px;
        }
      }
    }
    .solutions {
      .card-container {
        .card:nth-child(2), .card:nth-child(4) {
          flex-direction: row-reverse;
        }
        .card {
          margin: 0;
          display: flex;
          width: 100%;
          min-height: 200px;
          align-content: center;
          justify-content: center;
          h3 {
            text-align: left;
            padding-left: 18px;
          }
        }
        .phantom {
          display: none;
        }
      }
    }
    .risks {
      .card-container-scroll {
        margin: 24px 0 0;
      }
    }
    .audit {
      text-align: center;
      .subheader {
        padding: 0 8px;
      }
      .ListSubheader {
        text-align: center;
      }
      .container {
        .card {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 24px;
          .card-image {
            height: 180px;
          }
        }
      }
    }
    .customers .container img {
      max-height: 50px;
    }
  }

  @media (max-width: 315px) {
    .search {
      .header {
        padding: 8px;
        h1 {
          font-size: 18px;
        }
        .cube {
          height: 110px;
          width: 85px;
          svg {
            width: 50px !important;
          }
        }
      }
    }
  }


}


.pricelist {

  h1,h2 {
    margin-top: 40px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 30px;
    display: inline-block;
    text-align: center;
  }

  h3 {
    text-align: center;
    text-transform: capitalize;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    cursor: default;

    .card {
      margin: 8px;
      width: 250px;
      min-width: 250px;
      transition: all 500ms;

      .card-list {
        padding: 8px;
      }
      .card-list>div {
        list-style: none;
        display: flex;
        justify-content: space-between;
      }
      .card-list>div>p:nth-child(1){
        font-weight: 200;
        line-height: 1.75;
      }
      .card-list>div>p:nth-child(2){
        color: $color-primary;
        font-weight: 400;
      }

      .price {
        padding: 8px;
        text-align: center;
      }
      a {
        text-align: center;
      }

      .price-action {
        width: 100%;
        text-align: center;
      }
    }

    .card-dashed {
      background: transparent;
      border: 1px dashed #ff6600;
      border-spacing: 24px;
      box-shadow: initial;
    }

    .card:hover {
      transform: scale(1.1);
    }
  }
}

@media (max-width: 471px) {
  .pricelist {
    h1,h2 {
      padding: 0;
    }

    .container {
      overflow: scroll;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: initial;

      .card:nth-child(4) {
        margin-right: 18px;
      }
      .card:hover {
        transform: initial;
      }
    }
  }
}