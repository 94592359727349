$color-primary: #FF6600;
$color-primary-dark: #38383A;

$color-secondary-dark: #252527;

$color-red: #f44336;
$color-yellow: #ffeb3b;
$color-grey: #ccc;
$color-grey-sub: #f9f9f9;
$color-grey-sub-text: #9E9EA9;
$color-green: #4CAF50;
$color-dark-white: rgba(255, 255, 255, 0.87);
$color-dark-white-pure: rgba(255, 255, 255, 0.7);
$color-dark-white-solid: #fafafa;
$color-dark-white-trasparent: rgba(255, 255, 255, 0.3);
$color-primary-dark-pure: rgba(0, 0, 0, 0.7);
$color-primary-dark-trasparent: rgba(56, 56, 58, 0.7);

$shadow-5: 0 5px 10px rgba(0,0,0,0.16), 0 5px 10px rgba(0,0,0,0.23);
$shadow-4: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
$shadow-2: 0 2px 10px rgba(0,0,0,0.1), 0 2px 10px rgba(0,0,0,0.12);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');
@import "ai";
@import "landing";
@import "products";
@import "team";

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: Inter, sans-serif;
  margin: 0 !important;
  background-color: #fafafa;
  //-ms-overflow-style: none;  // IE 10+
  //scrollbar-width: none;  // Firefox
}
body::-webkit-scrollbar {
  //display: none;  // Safari and Chrome
}

#body {
  height: 100%;
  width: 100%;
}

@media (min-width: 960px) {
  #body {
    max-height: calc(100% + 90px);
  }
}

.grecaptcha-badge { visibility: hidden; }

iframe#launcher {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.vulners-font-h1 {
  font-weight: 400;
  font-size: 2em;

  -webkit-margin-before: 0.67em;
  -webkit-margin-after: 0.67em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

//Override captcha style
.g-recaptcha {
  >div{
    margin: 0 auto;
    >div{
      margin: 0 auto;
    }
  }
  margin: 10px 0;
}

// Temporary until move TablePanel.jsx to functional component
.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: 0;
}

.clear {
  clear: both;
}

.min-width-256 {
  min-width: 256px;
}

.flex1 {
  flex: 1;
}

.list-clear {
  list-style: none;
}

.link, .inner-link a {
  color: #216BA5;
  text-decoration: initial;
}

.vulners-href {
  color: $color-primary;
  text-decoration: initial;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-10 {
  margin: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-right-5 {
  margin-right: 5px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-lr-5 {
  margin: 0 5px;
}

.margin-lr-10 {
  margin: 0 10px;
}
.margin-lr-16 {
  margin: 0 16px;
}
.margin-tlr-30 {
  margin: 30px 30px 0 30px;
}

.max-height-500 {
  max-height: 500px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-300 {
  max-width: 300px;
}

.height-100-prc {
  height:100%;
}

.display-inline {
  display: inline;
}

.display-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.padding-right {

}

.left-40-prc {
  left: 40%;
}

.font-300 {
  font-weight: 300;
}

.font-600 {
  font-weight: 600;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.margin-0-auto {
  margin: 0 auto;
}

.vulners-hidden {
  display: none;
  visibility: hidden;
}

.vulners-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.vulners-ellipsis-oneline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vulners-ellipsis-oneline-inner > div > div > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vulners-text-main {
  color: rgba(0, 0, 0, 0.870588);
  display: block;
  font-size: 15px;
}
.padding-16 {
  padding: 16px;
}
.padding-lr-4 {
  padding: 0 4px!important;
}
.padding-lr-16 {
  padding: 0 16px!important;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-top-16 {
  padding-top: 16px;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.vulners-flexbox-column {
  display: flex;
  flex-direction: column;
}

.vulners-flexbox-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vulners-align-center {
  align-items: center;
}

.vulners-flexbox-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.vulners-flexbox-row-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.preloader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vulners-score-text {
  margin-left: 25px;
  font-size: 24px;
  width: 35px;
}

.vl-button {
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding: 0 16px;
  cursor: pointer;
  box-shadow: $shadow-2;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  svg {
    color: white !important;
  }
}
.vl-button:hover {
  opacity: 0.95;
  box-shadow: $shadow-5;
}

.vl-swipe-pagination {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .arrow {
    opacity: 0;
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    cursor:pointer;
  }
  .arrow:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}
.vl-swipe-pagination:hover {
  .arrow {
    opacity: 1;
  }
}

.vl-background-white-30 {
  background-color: $color-dark-white-trasparent !important;
}
.vl-background-black-70 {
  background-color: $color-primary-dark-pure !important;
}

// Hide content on mobile devices
@media (max-width: 440px) {

  .vulners-mobile-hidden {
    display: none !important;
  }

  .vulners-table-panel td{
    word-break: keep-all !important;
  }
}

.vulners-flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.vulners-flex-container-wrap {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.vulners-card-container-flex {
  flex: 1;
  display: flex;
  padding: 0 30px;
  transition: margin 0.3ms, padding 0.3ms;
  width: 100%;

  > div {
    display: flex;
    > div {
      margin-right: 30px;
    }
  }
}

.vulners-list-scrollable {
  word-wrap: break-word;
  white-space: normal;
  overflow-y: scroll;
  width: 316px;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.flex-start {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}

.vulners-width-100 {
  width: 100% !important;
}
.vulners-width-100-margin-right-45 {
  width: 100%;
  margin-right: 45px;
}

.vulners-hide-scroll, body {
  -ms-overflow-style: none;  // IE 10+
  scrollbar-width: none;  // Firefox
}
.vulners-hide-scroll::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;  // Safari and Chrome
}

.vulners-center-auto {
  margin: 0 auto;
}

.vulners-100 {
  width: 100%;
}
.vulners-child-100 > div {
  width: 100% !important;
}
.vulners-center-30 {
  margin: 0 30%;
  text-align: center;
}

.vulners-toolbar-text {
  margin: 15px 25px 0 10px;
}

.vulners-primary-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
}
.vulners-subtitle {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.vulners-center-panel {
  height: 45%;
  font-size: 14px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.vulners-divider-20 {
  display: block;
  width: 100%;
  height: 20px;
}

.vulners-text-area {
  min-height: 100px;
  width: 100% !important;
}


.vulners-table-default {
  padding-top: 0 !important;
}

.vulners-inline-fields {

}

.vulners-button-vertical-inline {
  margin-top: 20px !important;
  span {
    padding-left: 0 !important;
    text-transform: none !important;
  }
}

.vulners-tab-dark {
  >div:first-child {
    background-color: $color-primary-dark !important;
  }
}

.vulners-label {
  color: rgba(0, 0, 0, 0.498039);
  line-height: 22px;
  font-size: 13px;
  font-weight: 500 !important;
  margin-top: 14px;
}

.vulners-toggle-inline {
  width:150px !important;
}

.vulners-highlight {
  color: $color-primary !important;
  font-weight: 700 !important;
}

.vulners-code-highlight {
  position: relative;
}
.vulners-code-highlight-actions {
  position: absolute;
  right: 0;
  display: none;
}
.vulners-code-highlight:hover .vulners-code-highlight-actions {
  display: initial;
}
.vulners-code-highlight pre {
  margin: 1em 0 0;
}

.vulners-highlight-removed {
  background-color: #ffecec !important;
}

.vulners-highlight-added {
  background-color: #dbffdb !important;
}

.vulners-icon-logo {
  background-image: url(/static/img/logo.png);
  width: 200px;
  height: 40px;
}

.vulners-landing-grid-card-media-button {
  position: relative;
  top: -66px;
  left: 103px;
  width: 28px !important;
  height: 28px !important;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;
  background-color: #F26722;
}

.vulners-background-red, .vulners-badge-red > span {
  background-color: #f44336 !important;
}
.vulners-background-yellow, .vulners-badge-yellow > span {
  background-color: #ffeb3b !important;
}
.vulners-background-grey, .vulners-badge-grey > span {
  background-color: #ccc !important;
}
.vulners-color-main {
  color: $color-primary !important;
}
.vulners-color-main-grey {
  color: rgba(0, 0, 0, 0.541176) !important;
}

.vulners-color-dark-white-pure {
  color: $color-dark-white-pure !important;
}
.vulners-color-dark-white {
  color: $color-dark-white !important;
}


.vulners-field-date {
  display: inline-block;
}

.vulners-link-initial {
  text-decoration: initial;
  color: initial;
}
.vulners-link-initial-hovered:hover {
  color: $color-primary-dark;
}

.vulners-no-border {
  background-color: transparent !important;
  border-color: transparent !important;
}

/**   <H1>    **/
.vulners-h1 {
  width: 100%;
  display: inline-block;
}

.vulners-h1 h4 {
  margin: 10px 0;
}
.vulners-h1-small-dollar {
  font-size: 26px;
  color: $color-green;
}

.vulners-h1-highlight p {
  word-wrap: break-word;
}

.vulners-h1-highlight p, .vulners-h1-highlight ol {
  margin: 0;
}

.vulners-h1-highlight a {
  color: #216BA5;
  text-decoration: initial;
}

.vulners-h1-additional {
  float: right;
  width: 250px;
}

.vulners-h1-description {
  position: relative;
  margin-right: 270px;
}

@media (max-width: 480px) {
  .vulners-h1-additional {
    float: none;
    width: inherit;
  }
  .vulners-h1-description {
    margin-right: 0;
  }
}

/**   </H1>    **/


/**  <Audit>    **/

.vulners-audit-results {
  display: inline-block;
  width: 100%;
  .vulners-audit-results-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div {
      margin-bottom: 10px;
    }
  }
  .vulners-item-full {
    box-shadow: rgba(0, 0, 0, 0.156863) 0px 1px 10px, rgba(0, 0, 0, 0.227451) 0px 1px 10px;
  }
}

.vulners-audit-results-error {
  text-align: center;
  vertical-align: middle;
  height: 80px;
  span {
    vertical-align: middle;
    margin-top: 60px;
    display: block;
  }
  svg {
    margin-left: 20px;
    top: 5px;
    position: relative;
  }
}

.vulners-audit-cvss {
  position: absolute;
  left: -50px;
  top: 8px;
}

.vulners-audit-cvss-root {
  width: 270px !important;
  .vulners-vector {
    width: 205px;
    > button {
      float: right;
    }
  }
}

.vulners-audit-cvss-root-arrow {
  position: absolute;
  top: 13px;
  right: 12px;
}

.vulners-audit-list-item {
  >div {
    >div {
      margin-left: 60px !important;
    }
  }
}

.vulners-audit-scan-doughnuts {
  display: flex;
  max-width: 48%;
  >canvas {
    flex: 1;
  }
}

.vulners-audit-help {

}

/**  </Audit>    **/


/**  <Scan>  **/

.vulners-scan-table-canvas-inline {
  canvas {
    max-height: 48px;
  }
}

/**  </Scan>  **/

/**   <Appercut>   **/

.vulners-appercut {
  white-space: initial !important;

  .vulners-card-text{
    white-space: normal !important;
    word-wrap: break-word;
  }
}
.vulners-appercut-tab {
  color: $color-primary-dark !important;
}

.vulners-appercut-stepper {
  > div {
    display: flex;
  }
}
.vulners-appercut-list-item {
  padding: 0 18px;
}

/**   </Appercut>   **/

/**   Landing  **/

.vulners-landing {
  height: 100%;

   > div:nth-child(2) {
    min-height: calc(100% - 64px);
  }
}

.vulners-landing-article {
  padding: 40px 80px;
}

.vulners-landing-fixed-back {
  background-color: #252527;
  background-image: url("/static/img/vulners_logos_dark_shadow.png");
  background-size: 24%;
  position: absolute;
  width: 100%;
  height: 350px;
  top: 0;
  z-index: -2;
}

.vulners-main-logo {
  background: url("/static/img/logo.png") no-repeat;
  background-size: contain;
  float: right;
  height: 48px;
  width: 228px;
}

.vulners-landing-search-header {
  background: #fff;
}
.vulners-landing-header {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vulners-navigation-tabs {
  .button {
    padding: 0 !important;
  }
}

.vulners-landing-header-bar-wrapper-fixed {
  position: fixed;
  transition: width 0.3s;
  z-index: 200;
  box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  top: 0;
  left: 0;
  width: 100%;
  background: url("/static/img/background.png") !important;
}

.vulners-landing-header-bar-wrapper-fixed > div {
  margin: 0 auto;
  max-width: 960px;
}

.vulners-landing-tab-full-width {
  max-width: initial !important;
  width: 100%;
  margin: 50px auto 0;
  transition: margin 0.3ms, padding 0.3ms;
}

.vulners-landing-tab {
  flex:1;
  //min-height: 100%;
  max-width: 960px;
  margin: 58px auto 0;
  transition: margin 0.3ms, padding 0.3ms;
  width: 100%;

  h1 {
    margin-top: 0 !important;
  }

  .vulners-search-bar-wrapper {
    display: table-cell;
  }
  .vulners-search-bar {
    height:100%;
  }

  > div {
    padding: 20px;
  }
}

.vulners-landing-item-tab {
  //min-height: 100%;
  max-width: 960px;
  margin: 0 auto;
  transition: margin 0.3ms, padding 0.3ms;
  width: 100%;

  > div {
    padding: 20px;
  }
}

.vulners-search-help a,
.vulners-landing-tab a {
  color: #FE6600;
}

.vulners-navigation-tabs {
  float: left;
  transition: width 0.3s;
  div {
    background-color: transparent !important;
    div {
      padding: 0 5px;
      width: initial !important;
    }
  }

  .tab-menu>div, .tab-menu>div>div {
    padding-right: 0;
    .tab-menu-item {
      margin-bottom: 0!important;
      padding: 3px 0 0;
    }
  }
}

.vulners-navigation-tabs > div {
  background-color: transparent !important;
}

.vulners-landing-header-buttons {
  float: right;
}

.vulners-landing-header-buttons-help {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.6);
  outline: none;
  font-size: 14px;
  font-weight: 500;
  width: 135px;
  padding: 15px 0;
  float: left;
}

.vulners-landing-header-logo {
  height: 30px;
  float: left;
  padding: 9px;
  background: url(/static/img/logo.png);
  display: block;
  cursor: pointer;
  width: 160px;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: 9px;
}

.vulners-landing-counter {
  padding: 35px;
  z-index: 0;
  cursor: pointer;
  background-color: #252527 !important;
  color: white;
  margin: 0;
  list-style: none;
}

.vulners-landing-counters {
  margin-top: 20px;
}

.vulners-landing-counter-header {
  display: table;
}

.vulners-landing-counter-header img {
  height: 80px;
}

.vulners-landing-counter-header > div {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: 35px;
  margin: 20px;
}

.vulners-landing-counter-text {
  height: 50px;
}

.vulners-landing-counter-inner {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.vulners-landing-counter :hover {
  z-index: 20;
}

.vulners-button-negative {
  display: table;
}

.vulners-button-negative > div {
  display: table-cell;
  vertical-align: middle;
}

.vulners-button-negative > svg {
  fill: white !important;
  display: table-cell;
}

.vulners-landing-counter-wrapper {
  display: inline-block;
  font-size: 16px;
  vertical-align: top;
  width: 50%;
  padding: 0;
  background-color: #252527;
  transition: -webkit-filter 0.1s ease-out, filter 0.1s ease-out;
}

.vulners-landing-counter-wrapper :hover {
  z-index: 1000;
  filter: brightness(103%);
  -webkit-filter: brightness(103%);
}

.vulners-landing-grid {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px !important;
}

.vulners-landing-grid-actions {
  background-color: #252527;
  color: $color-dark-white;
  font-size: 13px !important;
  padding: 8px 14px !important;
  line-height: 25px !important;
  a {
    margin: 0!important;
  }
  button {
    color: $color-dark-white;
    margin: 0!important;
    height: inherit!important;
    width: inherit!important;
    padding: inherit!important;
  }
}

.vulners-landing-grid-card-rullet-wrapper {
  overflow-x: scroll;
  .vulners-landing-grid-card-rullet {
    display: inline-flex;
  }
}

.vulners-landing-grid-button-more {
  margin-top: 30px;
}

.vulners-landing-grid-button-more > div {
  display: block;
  width: 100%;
}

.vulners-landing-grid-wrapper {
  padding: 0 !important;
  display: inline-block;
  width: 100%;
}

.vulners-landing-grid-card {
  cursor: pointer;
  height: 258px;
  width: 160px;
  margin: 0 0 16px 0;
}

.vulners-landing-grid-card-media {
  border-radius: 100%;
  background: white;
}

.vulners-landing-grid-card-media > div {
  padding: 15px
}

.vulners-landing-grid-card img {
  border-radius: 30%;
  max-height: 200px;
  max-width: 200px !important;
  transition: all 0.3s ease-out;
}

.vulners-landing-grid-card-text {
  background-color: #252527;
  line-height: 25px;
  height: 25px;
}

.vulners-landing-grid-card-text > span {
  color: rgba(255, 255, 255, 0.87) !important;
}

.vulners-landing-grid-card-text > span:first-of-type {
  font-size: 13px !important;
}

/**   Landing  **/

.vulners-search-help {
  padding: 16px;
}
.vulners-search-help li {
  list-style-type: none;
}
.vulners-search-help code {
  white-space: normal;
}


/* <CVELineChart> */

.vulners-chart {
  max-height: 300px;
}

.vulners-chart-select-tools {
  >div {
    margin-right: 20px;
  }
}

/*<SearchBar>*/
.vulners-search-list {
  max-width: 960px;
  margin: 0 auto;
  transition: top 0.3ms;
  min-height: 100%;
  width: 100%;
}

.vulners-search-list > div {
  margin-top: 16px;
}

.vulners-search-panel {
  background-image: url(/static/img/background_new.jpg);
  background-size: 100%;
  z-index: 2;
  top: 0;
  width: 100%;
  position: fixed;
  transition: top 0.3s;
  box-shadow: $shadow-2;
}


.vulners-search-panel.scroll-down {
  top: -50px;
}
@media (max-width: 700px) {
  //.vulners-search-panel.scroll-down {
  //  top: -45px;
  //}
}

@media (max-width: 1239px) {
  .vulners-search-panel {
    top: 0;
  }
  .vulners-landing-tab {
    padding: 0;
  }
}

@media (max-width: 440px) {
  .vulners-landing-counter-wrapper {
    display: block;
    width: initial;
  }
  .vulners-search-list > div {
    margin: 8px;
  }
}

.vulners-header-search-bar-wrapper {
  box-shadow: $shadow-2;
}

.vulners-header-search-bar-logo {
  height: 48px;
  width: 48px;
  img {
    height: 48px;
    width: 48px;
  }
}

.vulners-header-search-navigation-tabs-wrapper {
  background-color: #38383A;
}

.vulners-search-toolbar-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.vulners-header-search-bar-wrapper .vulners-search-toolbar-settings {
  width: 100%;
  max-width: 960px;
}

.vulners-header-search-bar-wrapper .vulners-navigation-tabs {
  float: none;
  width: 100%;
  margin: 0 auto;
  max-width: 960px;
  background-color: #38383A;
}

.vulners-header-search-bar-wrapper .vulners-navigation-tabs > div {
  //max-width: 350px;
}

.vulners-search-bar {
  display: table;
  width: 100%;
  padding: 125px 0;
}

.vulners-search-bar-wrapper {
  display: table-cell;
  vertical-align: middle;
}

@media (max-width: 480px) {
  //.vulners-search-bar-moved .vulners-search-bar {
  //  padding: 0;
  //}
  //.vulners-search-bar-moved .vulners-search-bar-wrapper {
  //  display: block;
  //}
}

.vulners-search-bar-small {
  padding: 25px;
}

.vulners-search-bar-group-left {
  float: left;
  width: 100%;
  margin-right: -100px;
  > div {
    width:100% !important;
  }
}
.vulners-search-bar-group-right {
  float: right;
}

.vulners-search-field {
  width: 100% !important;
}

.vulners-search-toolbar {
  background-color: transparent !important;
  width: 60% !important;
  margin: 0 auto;
  border: #ff8a65 3px solid;
  min-height: 48px!important;
}

.vulners-search-toolbar-settings {
  width: 60%;
  margin: 0 auto;
}

.vulners-search-toolbar-settings > div {
  margin: 0 5px;
  display: block;
}

.vulners-search-bar-button-help {
  right: -80px;
  position: absolute !important;
}

@media (min-width: 440px) {
  .vulners-navigation-tabs {
    > div {
      button {
        width: initial !important;
      }
    }
  }
}

@media (max-width: 860px) {
  .vulners-navigation-tabs {
    width: 100%;
    background-color: $color-primary-dark;
    .tab-menu {
      width: 100% !important;
    }
  }
  .vulners-landing-header-logo {
    //height: 42px;
  }
  .vulners-landing-header-buttons {
    padding: 8px 0;
  }
  .vulners-landing-tab {
    padding: 0;
    margin-top: 64px;
  }
  .vulners-landing-tab-full-width {
    padding: 0;
    margin-top: 120px;
  }
  .vulners-landing-item-tab {
    padding: 0;
    margin-top: 0;
  }
  .vulners-landing-header-buttons > button {
    position: initial !important;
  }
}

@media (max-width: 480px) {
  .vulners-search-toolbar {
    width: 90% !important;
  }
  .vulners-search-bar-button-help {
    display: none !important;
  }
  .vulners-search-toolbar-settings {
    flex-wrap: wrap;
    display: none;
  }
  .vulners-landing-header-logo {
    background: url(/static/img/logo_small.png);
    width: 30px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: 5px;
  }
  .vulners-landing-tab > div, .vulners-landing-item-tab > div {
    padding: 10px;
  }
  .vulners-landing-grid-actions > span:first-of-type {
    font-size: 11px !important;
  }
  .vulners-subscribe {
    display: block !important;
  }
}

@media (max-width: 350px) {
  .vulners-landing-grid {
    justify-content: initial;
  }
}

/*</SearchBar>*/


/* <Speller> */

.vulners-speller {
  margin: 20px auto 0;
  max-width: 960px;

  div {
    padding: 0 36px;
    color: grey;
    span {
      cursor: pointer;
      margin: 0 8px;
      font-size: 18px;
      color: $color-primary;
    }:hover {
     text-decoration: underline;
    }
  }
}

/* </Speller> */


/* <Vote> */

.vulners-vote {
  button {
    min-width: initial !important;
  }
  span {
    font-weight: bold !important;
    font-size: 20px !important;
    display: inline-block;
  }
  .vulners-vote-details {
    width: initial !important;
    height: initial !important;
    padding: initial !important;
  }
  .vulners-vote-value {
    display: inline-block;
    fill: rgba(0, 0, 0, 0.870588);
    height: 24px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    vertical-align: middle;
    -webkit-user-select: none;
  }
}


/* <Footer> */

.vulners-simple-footer-bar {
  position: relative;
  border-radius: 0!important;
  padding: 8px;
  padding-right: 70px;
  min-height: 75px;
  justify-content: space-around;
  color: #FFFFFF !important;
  background-color: #252527 !important;
  text-align: center;

  .vulners-simple-footer-bar-links {
    padding: 12px;
  }

  .vulners-simple-footer-bar-agreements {
    a {
      color: white;
    }
  }

  h6 {
    font-weight: 100;
    margin: 0 15px 0 5px;
  }
}

.vulners-footer-bottom {
  display: flex;
  font-size: 12px;
  margin-right: 5px;

  .powered-by {
    margin: 0 10px;

    a {
      display: flex;
      height: 44px;
      align-items: flex-end;
    }

    .powered-text {
      margin-right: 10px;
    }

    .vulners-servers-logo {
      height: 25px;
    }
    .vulners-qrator-logo {
      height: 36px;
      padding-bottom: 4px;
    }
    .vulners-wallarm-logo {
      height: 40px;
      margin-bottom: 5px;
    }
  }

}

/* <Footer/> */

/*<HeaderSearchBar>*/

.vulners-header-bar {
  width: 100%;
  padding: 8px 240px 0 64px; /* LC fullwidth and RC fullwidth + CC padding */
  min-height: 64px;
}

.vulners-header-search-bar {
  background: url("/static/img/background_new.jpg");
  background-color: #38383A;
  background-size: 100%;
  width: 100%;
  padding: 8px 0; /* LC fullwidth and RC fullwidth + CC padding */
  min-height: 64px;
  border-radius: 0 !important;
}

.vulners-header-search-bar input {
  color: white !important;
}

.vulners-header-search-bar .vulners-search-field > div {
  color: rgba(255, 255, 255, 0.87) !important;
}

.vulners-header-search-bar > div {
  max-width: 960px;
  margin: 0 auto;
}

.vulners-header-bar-menu {
  float: left;
  display: none !important;
}

.vulners-header-bar-navigation {
  position: relative;
  float: left;
  max-width: 750px;
  width: 100%;
}

.vulners-header-search-bar {
  .vulners-login-pane {
    float: right;
  }
}

.vulners-header-bar-navigation-inner {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  max-width: 650px;
  margin-left: 10px;
}

.vulners-header-bar-navigation-text {
  width: 100%;
  padding-left: 10px;
}

.vulners-header-bar-navigation-buttons-block {
  position: absolute;
  right: 100px;
  top: 0;
}

.vulners-header-bar-navigation-text hr {
  bottom: 11px !important;
  position: relative !important;
}

.vulners-header-bar-navigation .vulners-navigation-forward {
  float: left;
  width: 48px;
  margin-left: -48px
}

.vulners-header-bar-navigation .vulners-navigation-clear {
  float: left;
  width: 48px;
  margin-left: -96px
}

.vulners-header-bar-icon {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: relative;
  background: url("/static/img/logo.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  float: left;
  height: 48px;
  width: 228px; /* RC width */
  margin-right: -228px; /* RC fullwidth + CC padding */
}

@media(max-width: 990px) {
  .vulners-header-bar-menu {
    display: inline-block !important;
  }
  .vulners-header-search-bar {
    .vulners-login-pane {
      display: none;
    }
  }
  .vulners-header-search-bar-logo {
    display: none;
  }
}

@media(max-width: 660px) {
  .vulners-header-bar-navigation {

  }
  .vulners-header-bar-navigation-buttons-block {
    right: 10px;
  }
}

@media (max-width: 480px) {
  .vulners-header-search-bar {
    padding-right: 6px;
  }
  .vulners-header-search-bar .vulners-header-bar-icon {
    display: none;
  }
  .vulners-header-search-bar .vulners-navigation-clear {
    display: none !important;
  }
}

/* <Drawer> */

.vulners-drawer {
  a {
    color: initial;
    text-decoration: initial;
  }
}

.vulners-drawer-card {
  background: url("/static/img/background_new.jpg");
  background-color: #38383A;
  background-size: 140%;
  width: 100%;
  min-height: 64px;
}

.vulners-drawer-card-blank {
  width: 100%;
  min-height: 64px;
  display: inline-flex;
}

.vulners-simple-header-bar {
  padding: 8px;
  min-height: 64px;
}

.vulners-simple-header-bar-menu {
  width: 48px; /* LC width */
  position: relative;
  float: left;

}

.vulners-simple-header-bar-icon {
  background: url("/static/img/logo.png") no-repeat;
  background-size: contain;
  float: right;
  height: 48px;
  width: 228px;
}

/*</HeaderSearchBar>*/

.vulners-logo-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

/** Items **/
.vulners-item {
  overflow: hidden;
  width: 100%;
}
.vulners-item:hover {
  background-color: $color-dark-white-solid;
}
.vulners-item-link {
  color: #ff7043;
  float: right;
}

.vulners-avatar {
  background-color: transparent !important; /*TODO*/
  border-color: white !important;
}

.vulners-item-header img {
  background-color: transparent !important; /*TODO*/
  border-color: white !important;
}

.vulners-item-cvss-score {
  margin-left: 50px;
  height: 25px;
  float: right;
  padding: 8px;
}

.vulners-item-history-card {
  margin-bottom: 20px;

  .vulners-item-history-card-header {
    display: flex;
    justify-content: space-between;
    >span {
      padding-right: 48px;
    }
  }

}
.vulners-item-history-toggles {
  padding: 0 16px;
  max-width: 300px;
}

.vulners-item-history-fixed {
  position: fixed !important;
  top: 0;
  width: 100%;
  transition: width 0.3s, top 0.3s;
}

.vulners-item-history {
  padding: 0 16px;
  display: flex;
  flex: 1 100%;

  .vulners-item-history-slider-group {
    flex: 1;
  }

  .vulners-item-history-slider-label {
    line-height: 42px;
    margin-right: 32px;
  }

  .vulners-item-history-slider {
    div {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
  .vulners-item-history-date {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12px;

    div {
      cursor: pointer;
      min-width: 24px;
      text-align: center;
    }
  }
  .vulners-item-history-date-select {
    width: 220px !important;
    margin-left: 38px;
  }
}

.vulners-item-history-backlight {
  background-color: rgba(0, 0, 0, 0.541176);
  .vulners-item-history-highlight {

  }
}

.vulners-item-advertisement {
  box-shadow: #ff6600 0 0 6px, #ff6600db 0 1px 4px !important;
  .vulners-card-header img {
    display: block!important;
    min-width: 40px;
  }
  .vulners-card-header-title {
    white-space: pre-wrap;
  }
  .vulners-card-text {
    white-space: pre-wrap;
  }
}

.vulners-item-id {
  word-break: break-all;
}
.vulners-item-description {
  word-wrap: break-word;
  white-space: normal !important;

  a {
    color: $color-primary;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  code {
    word-wrap: break-word;
    white-space: normal !important;
  }

  ul {
    max-height: 300px;
    overflow-y: scroll;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none;  // Firefox
  }
  ul::-webkit-scrollbar {
    display: none;  // Safari and Chrome
  }
}

.vulners-item-highlight {

  .vulners-item-full {
    //background-color: $color-grey !important;
  }
}

.vulners-item-highlight-id .vulners-item-id,
.vulners-item-highlight-cpe .vulners-item-cpe,
.vulners-item-highlight-type .vulners-item-type,
.vulners-item-highlight-cvss .vulners-card-vectors,
.vulners-item-highlight-reporter .vulners-item-reporter,
.vulners-item-highlight-modified .vulners-item-modified,
.vulners-item-highlight-published .vulners-item-published,
.vulners-item-highlight-affected .vulners-item-affected,
.vulners-item-highlight-mirror .vulners-item-mirror,
.vulners-item-highlight-naslFamily .vulners-item-naslFamily,
.vulners-item-highlight-references .vulners-item-references,
.vulners-item-highlight-description .vulners-item-description,
.vulners-item-highlight-affectedPackage .vulners-item-vulners-table,
.vulners-item-highlight-affectedSoftware .vulners-item-vulners-table {
  background-color: $color-dark-white !important;
  padding: 5px 16px !important;
  margin: 7px 0;
  border-radius: 1px;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.32) 0 1px 6px, rgba(0, 0, 0, 0.32) 0 1px 4px;
}

/** Table **/
.vulners-table, .vulners-item-cpe {
  td {
    text-overflow: initial !important;
  }
  > div {
    max-height: 350px;
  }

  > div > div {
    display: inline;
  }
}

.vulners-table-sort {
  display: inline-flex;
  align-items: center;
}

.vulners-table-body-div {
  display: table;
  > div, > a {
    display: table-cell;
  }
}

.vulners-table-body {
  display: table;
}

.vulners-table-cell {
  display: table-cell;
}

/** Exact Match **/
.vulners-exact-match {
  background-color: $color-primary-dark !important;
  box-shadow: $shadow-5;
  position: relative;
  top: -12px;

  .vulners-exact-match-wrapper {
    max-width: 960px;
    margin: 16px auto 0;
  }

  .vulners-exact-match-references {
    display: inline-block;
    width: 100%;
  }

  .vulners-exact-match-references-wrapper-left {
    width: 70%;
    float: left;
    >div {
      padding: 20px 10px 15px 0
    }
  }

  .vulners-exact-match-references-wrapper-right {
    width: 30%;
    float: left;
    >div {
      padding: 20px 0 10px 10px
    }
  }

  .vulners-exact-match-references-wrapper {
    padding: 10px 10px 0 10px;
  }

  .vulners-exact-match-list {
    max-height: 300px;
    overflow-y: scroll;
    p {
      word-break: break-all;
    }
  }
}

/** SUBMISSION **/

.vulners-submission-text {
  width: 100%;
}



/** LOGIN **/

.vulners-login-pane {
  display: flex;
}

.vulners-dialog-content {
  max-width: 360px !important;
  margin: 0 auto !important;
}

.vulners-dialog-overlay {

}
.vulners-dialog-title {
  color: $color-primary !important;
  text-align: center;
  background-color: $color-dark-white !important;
}

.vulners-dialog-body, .vulners-dialog-body-preview {

  > div{
    width: 100% !important;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .vulners-dialog-body-text {
    margin-top: 25px;
  }
}

.vulners-dialog-body-preview {
  padding: 0 !important;
}

.vulners-dialog-error-text {
  margin: 10px 0;
  color: $color-primary;
  white-space: pre-line;
}

.vulners-dialog-item-body-preview {
  overflow-y: scroll !important;
  padding: 0 !important;
}

.vulners-login-pane-user {
  vertical-align: super;
  display: inline-block;
  padding: 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: $color-dark-white;
}

@media (max-width: 720px) {
  .vulners-dialog .MuiDialog-paper{
    margin: 12px;
  }
}
@media (max-width: 320px) {
  .vulners-dialog .MuiDialog-paper{
    margin: 0;
  }
}

.vulners-user-info-tab {
  >div:first-child {
    background-color: $color-primary-dark !important;
  }
}

.vulners-user-info-license {
  >div{
    >div, >svg {
      padding: 12px;
      vertical-align: middle;
      display: inline-block !important;
    }
    span {
      color: $color-primary
    }
    .vulners-user-info-license-about {
      float: right;
      height: 48px !important;
    }
  }
}

.vulners-subscribe {
  display: flex;
  display: -webkit-flex;
  padding-top: 0 !important;
  justify-content: space-around;
}

.vulners-subscribtion-table {
  padding-top: 0 !important;
  overflow-x: scroll;

  >div{
    min-width: 880px;
  }
}

.vulners-subscribe-item {
  display: inline-block;
}

.vulners-subscribe-item-actions {
  display: inline-block;
}

/**  Demo  **/

.vulners-demo-tour-message {
  margin-bottom: 20px;

  > div { // rewrite Card bottom
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0!important;
  }
}

.vulners-demo-tour-close {
  display: block;
}
@media (max-width: 700px) {
  .vulners-demo-tour-close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.vulners-demo-tour-icon {
  float: left;
  margin-right: 20px !important;
  background-color: $color-dark-white !important;
}

/** Vulners Custom Card **/
.vulners-item-full {
  margin: 0 auto;
  max-width: 960px;
}

.vulners-item-references {
  max-height: 300px;
  overflow-y: scroll;
}

.vulners-card-header {
  min-height: 72px;
  padding: 16px;
  box-sizing: border-box;
}

.vulners-card-header >img { /*avatar*/
  background-color: transparent !important;
  border-color: white !important;
  margin-right: 16px;
}

.vulners-card-header-text {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
}

.vulners-card-header-title {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 15px;
}

.vulners-card-header-sub-title {
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 14px;
}

.vulners-card-text {
  padding: 0 16px 8px 16px !important;
}

.vulners-item-full .vulners-card-text {
  white-space: pre-wrap;
  cursor: auto;
}

.vulners-item-small .vulners-card-text {
  word-break: break-all;
}

.vulners-item-full .vulners-card-header-text {
  cursor: auto;
}

.vulners-card-footer {
  height: 35px;
  padding: 12px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.vulners-card-footer-view-count {
  display: inline-block;
}
.vulners-card-source {
  display: flex;
}

.vulners-item .vulners-card-footer {
  .vulners-card-source {
    margin: initial;
  }
}

.vulners-card-social {
  float: left;
  margin-bottom: 5px;
}

.vulners-card-social td {
  cursor: pointer !important;
}

/** <Stepper> **/
.vulners-stepper {
  > div {
    display: flex;
    > div {
      > span {
        display: flex;
      }
    }
  }
}
@media (max-width: 700px) {
  .vulners-stepper>div {
    padding: 0;
    flex-wrap: wrap;
  }
  .vulners-stepper>div>div {
    padding-bottom: 20px;
  }
}


/** <HackAPP> **/

.vulners-hackapp-vector {
  float: right;
  span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -webkit-flex;
  }
}
.vulners-hackapp-full-vector  span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.vulners-hackapp-icon {
  margin-left: 15px;
}

/** </HackApp> **/



/** <Vector> **/
.vulners-card-vectors {
  text-align: right;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 8px;
  margin-left: 16px;

  .vulners-card-score {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;

    span {
      align-items: center;
      margin-left: 10px;
    }
  }
  .vulners-card-score-cvss {
    font-size: 12px;
  }
  .vulners-card-score-cvss-value {
    display: inline-block;
    text-align: right;
  }
}

.vulners-card-vectors > div {
  display: flex;
}

.vulners-vector {
  position: relative;

  button {
    width: initial !important;
    height: initial !important;
    padding: initial !important;
  }
}

.vulners-vector-score {
  cursor: pointer;
  float: right;
  font-size: 24px;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.vulners-vector-icons {
  float: left;
  display: flex;
}

.vulners-vector-item {
  float: left;
  margin-right: 5px;
  max-width: 100px;
}

.vulners-vector-tooltip {
  box-sizing: border-box;
  transform: initial !important;
}
@media (max-width: 700px) {
  .vulners-card-vectors {
    margin: 0;
  }
  .vulners-card-score-cvss-value {
    text-align: left;
    min-width: 32px;
  }
  .vulners-vector {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .vulners-vector-icons {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

/** </Vector> **/

.vulners-paging {
  max-width: 960px;
  width: 100%;
  margin: 20px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.vulners-paging-buttons {
  flex:1;
  display: flex;
  margin-left: 100px;
  justify-content: center;
}

.vulners-paging-total {
  margin: 10px;
  font-size: 14px;
  min-width: 90px;
  color: $color-primary-dark
}
.vulners-paging button {
  width: 150px;
}

@media (max-width: 700px) {
  .vulners-paging-buttons {
    margin-left: 0;
  }
  .vulners-paging button {
    flex: 1;
    width: initial;
  }
}

.vulners-paging-left .material-icons {
  margin: 5px;
  float: left;
}

.vulners-paging-right .material-icons {
  margin: 5px;
  float: right;
}

.vulners-paging-numerical {
  color: $color-grey-sub-text;
  font-size: 12px;
  font-weight: normal;
  justify-content: flex-end !important;
  align-items: center;
}

.material-icons {
  cursor: pointer;
}

.vulners-icons {
  cursor: pointer;
}

/** Colors **/
.vulners-color-white {
  background-color: white;
}

.vulners-color-grey {
  background-color: #3E3E3E;
}

.vulners-text-color-white {
  color: rgba(255, 255, 255, 0.87);
}

.vulners-text-color-primary {
  color: #FF6600;
}

/** References **/
.vulners-references-wrapper {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
}
.vulners-references {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 960px;
  margin-bottom: 25px;
  width: 100%;
}

.vulners-references-list {
  width: 295px;
  min-height: 408px;
  margin: 25px 25px 0 0;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media (max-width: 480px) {
  .vulners-item-history-slider-group {
    display: none;
  }
  .vulners-item-history-date-select {
    margin-left: 0 !important;
  }
  .vulners-references {
    display: block;
  }
  .vulners-references-list {
    float: none;
    width: initial;
    max-width: inherit;
    margin-right: inherit;
    min-height: inherit;
  }
  .vulners-paging-wrapper {
    padding: 0 8px;
  }
  .vulners-simple-footer-bar {
    padding-right: initial;
  }
  .vulners-simple-footer-bar > .vulners-flexbox-row {
    flex-direction: column-reverse;
    .vulners-footer-bottom {
      margin-bottom: 16px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      >div {
        margin-bottom: 8px;
      }
    }
  }
}

/** Collapsed Panel**/

.vulners-collapsed-panel {
  margin-top: 25px;
}

.vulners-collapsed-panel-button {
  width: 100%;
  text-align: left;
}

.vulners-collapsed-panel-button .material-icons {
  float: left;
  margin-top: 6px;
  margin-left: 20px;
}

.vulners-collapsed-panel-component > pre {
  margin: 1em 0 0
}



/*** DASHBOARD ***/

.vulners-dashboard {
  //max-height: 100%;
  //> div {
  //  overflow-y: scroll;
  //}
  font-weight: 300;

  li p {
    margin: 4px 0;
  }
}

.vulners-dashboard-menu {
  min-width: 250px;
  min-height: 100%;
}


.vulners-dashboard-body-full-width {
  flex:1;
  min-height: 100%;
  margin: 20px 30px 0;
  transition: margin 0.3ms, padding 0.3ms;
  width: 100%;

  >div {
    min-height: 100%;
  }
}


/*** MAILING ***/


.vulners-mailing-menu {
  max-width: 300px;
  margin: 30px 0 30px 30px;
  display: table;
}

.vulners-mailing-body-flex {
  flex:1;
  display: flex;
  padding: 30px;
  padding-bottom: 0;
  transition: margin 0.3ms, padding 0.3ms;
  width: 100%;

  >div {
    flex: 1;
    margin-right: 30px;
  }
}


// Conference

.vulners-conference {


  p {
    line-height: 24px;
    color: rgba(0,0,0,.54) !important;
  }


  .sponsors {
    padding-right: 40px;
    .komers1 {
      max-width: 150px;
    }
    .komers2 {
      max-width: 400px;
    }
    .qiwi {
      max-width: 130px;
    }
    .wallarm {
      max-width: 100px;
      padding-left: 9px;
    }
  }

  .vulners-conference-subtitle {
    display: flex;
    font-size: 16px;
    margin: 16px 0;

    > span {
      text-align: right;
      display:block;
      width: 100%;
      line-height: 36px
    }
  }

  .vulners-conference-subtitle {
    a {
      height: 100% !important;
    }
  }

  .vulners-conference-btn-register-text {
    text-align: right;
    flex: 1;
  }

  .vulners-conference-contacts {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 440px) {

    .sponsors {
      padding-right: 5px;
      .komers1 {
        max-width: 80px;
      }
      .komers2 {
        max-width: 130px;
      }
      .qiwi {
        max-width: 60px;
      }
      .wallarm {
        max-width: 52px;
        padding-left: 4px;
      }
    }

    .vulners-conference-title {
      line-height: 40px;
    }

    .vulners-conference-card-title {
      line-height: 30px;
    }

    .vulners-conference-btn-register-text {
      text-align: center;
      margin-top: 20px;
    }

    .vulners-conference-subtitle {
      display: block;
      text-align: center;
      padding: 20px;

      span {
        padding: 0 !important;
      }

      > span {
        text-align: center;
      }
    }

    .vulners-conference-contacts {
      display: block;
      justify-content: space-between;
    }

  }

}
