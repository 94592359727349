$defcon-black: #06070C;
$defcon-blue: #CCCCFF;
$defcon-human: #5AAFAC;
$defcon-ai: #A841B8;

$font-rationale: 'Rationale', sans-serif;

@import url("https://fonts.googleapis.com/css?family=Rationale");

.color-defcon-blue {
  color: $defcon-blue;
  text-shadow: 2px 5px 2px rgba(0, 0, 0, 0.25) !important;
  font-family: 'Rationale', sans-serif;
}

.color-defcon-human {
  color: $defcon-human;
}
.color-defcon-ai {
  color: $defcon-ai;
}


.vulners-ai {

  color: $color-dark-white!important;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  background-color: $defcon-black;
  background-image: url("/static/img/defcon/background.png");
  background-position: center 260px;
  background-size: 70% auto;
  background-repeat: no-repeat;

  .vulners-landing-header-bar-wrapper {
    height: 50px;
  }

  .vulners-ai-columns {

    flex: 1;
    display: flex;
    padding: 8px;
    max-height: 100%;
    flex-direction: row;
    justify-content: space-between;

    > div, > table {
      flex:1;
      display: flex;
      margin: 8px;
      width: 30%;
      height: 100%;
      max-height: 100%;
      overflow-y: hidden;
      color: $color-dark-white!important;
      flex-direction: column;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .vulners-ai-middle {
    max-width: initial;
    overflow: hidden;
    height: 100%;

    .brain-wrapper {

      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;

      .vulners-number-counter {
        font-size: 62px;
      }

      .brain-score {
        font-size: 34px;
        width: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: 'Rationale', sans-serif;
      }

      .brain {
        width: 170px;
        height: 170px;
        background: $color-dark-white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
      }
      .brain-loader {
        background: url(/static/img/brain_color.svg) no-repeat 50% 50%;
      }
    }

    .score-text {
      text-align: center;
      font-size: 24px;
      margin: 40px auto;
      .vulners-text-color-primary {
        font-size: 42px;
        font-family: $font-rationale;
        color: $defcon-human
      }
    }

    .vulners-card-vectors {
      margin-top: 0;
    }

    .vulners-vector-score {
      width: 35px;
    }

    .vulners-card-score {
      text-align: right;
    }

    .score-button-wrapper {
      display: flex;
      color: $color-dark-white-pure
    }

    .vulners-item-full {
      background-color: $color-dark-white-trasparent !important;
      padding: 0;
      color: $color-dark-white!important;

      overflow-y: scroll;

      .vulners-card-text,
      .vulners-card-header,
      .vulners-card-header-title,
      .vulners-card-header-sub-title{
        color: $color-dark-white!important;
      }

      .vulners-card-header, .vulners-card-text {
        background-color: rgba(0,0,0,0.7);
      }

      .vulners-card-header {
        margin: 15px;

        .vulners-card-header-title {
          color: $defcon-human;
          font-family: 'Rationale', sans-serif;
        }
      }

      .vulners-card-text {
        margin: 15px;
        padding-top: 20px !important;

        a {
          color: $defcon-human!important;
        }
      }

      .vulners-table {
        table, tr, th {
          background-color: inherit !important;
          color: $color-dark-white!important;
        }
      }
    }

    .vulners-ai-check-text {
      padding: 15px;


      textarea, div {
        color: $color-dark-white
      }
    }
  }

  .vulners-ai-title {
    font-size: 42px;
    text-align: center;
    margin: 20px auto 20px;
    font-family: 'Rationale', sans-serif;
  }

  .vulners-ai-left {
    flex: initial!important;
    width: 25% !important;

    >div {
      height: 100%;
      padding: 0 16px;
      >ul {
        overflow-y: scroll;
        max-height: 100%;
      }
    }

    .item-animation {
      background-color: rgba(0, 0, 0, 0.7)!important;
      position: relative !important;
      transition: width 3s ease-in, height 3s ease-in, background-color 3s ease-in, transform 3s ease-in;
      left: 0;
      opacity: 1;
      height: 100%;
      margin: 10px 0 !important;

      >div>div span {
        font-family: 'Rationale', sans-serif!important;
      }
      >div>div>div:nth-child(3) {
        color: $defcon-blue!important;
        font-family: 'Rationale', sans-serif!important;
      }

      .item-animation-text p {
        color: $color-dark-white!important;
      }
    }

    .item-animation-first{
      color: $defcon-human!important;
      //background-color: $color-grey-sub !important;
    }

    .item-animation-first-selected{
      //background-color: $color-primary !important;
    }

    .item-animation-move {
      background-color: #ff6600 !important;
      left: 500px;
      opacity: 0;
      height: 0;
    }

    .vulners-search-field > div, .vulners-search-field > input {
      color: $color-dark-white !important;
    }
  }

  .vulners-ai-right {
    width: 35%;

    .vulners-ai-graph {
      max-height: 50%;
      height: 50%;
      >div {
        height: 100%;
      }
    }
  }

  .vulners-ai-result {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis!important;
    padding-right: 20px !important;

    .vulners-score-item-text {
      text-overflow: ellipsis;
      overflow: hidden !important;
      color: $color-dark-white!important;
      >div>div:last-child{
        color: $color-dark-white-pure
      }
    }

    >div:nth-child(3) {
      flex: 1;
      overflow-x: hidden;
    }

    .vulners-score-text {
      width: 35px;
      margin-left: 25px;
      font-size: 30px;
      text-align: right;
    }
  }
  .vulners-ai-result-header {
    >div, >svg {
      margin-left: 29px;
    }
    font-size: 25px;
  }

  .vulners-ai-footer {
    display: flex;
    margin-right: 10%;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;

    img {
      max-height: 80px;
      max-width: 240px;
    }
  }
}


@media (max-width: 860px) {

  .vulners-ai {

    background-position: center 70%;
    background-size: 100% auto;

    height: initial;
    padding: initial;
    overflow: scroll;

    .vulners-landing-header-bar-wrapper {
      height: initial;
    }

    .vulners-ai-columns {
      padding: 0;
      display: block;
      flex-direction: column;

      .vulners-ai-left {
        display: none;
      }

      .vulners-ai-middle {
        padding-top: 20px;
        padding-bottom: 20px;
        height: initial!important;
        display: flex;

        .brain-wrapper {
          .brain {
            position: relative;
            width: 120px;
            height: 120px;
            background: white;
          }
        }

        .vulners-item-full {
          overflow-y: hidden;
        }
      }

      > div, > table {
        width: 100%;
        margin: initial;
        overflow-y: auto;
      }

      > table {
        padding-top: 20px;
        padding-bottom: 20px;
        height: initial !important;
        display: flex;
        flex-direction: column;
      }
    }

    .vulners-card-text {
      .vulners-table {
        display: none;
      }
      .vulners-item-references {
        display: none;
      }
    }

    .vulners-ai-footer {
      margin-right: initial;
    }
  }

}

