$color-primary: #FF6600;
$color-primary-dark: #38383A;

$color-secondary-dark: #252527;

$color-red: #f44336;
$color-yellow: #ffeb3b;
$color-grey: #ccc;
$color-grey-sub: #f9f9f9;
$color-green: #4CAF50;
$color-dark-white: rgba(255, 255, 255, 0.87);
$color-dark-white-pure: rgba(255, 255, 255, 0.7);

$shadow-5: 0 5px 10px rgba(0,0,0,0.4), 0 5px 10px rgba(0,0,0,0.4);
$shadow-4: 0 4px 10px rgba(0,0,0,0.4), 0 4px 10px rgba(0,0,0,0.4);
$shadow-2: 0 2px 10px rgba(0,0,0,0.4), 0 2px 10px rgba(0,0,0,0.4);

$chevron: polygon(100% 0, 100% 100%, 0% 100%, 25% 50%, 0% 0%);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&display=swap');

.vl-products {

  p, h1, h2, h3, h4, h5 {
    margin: 0;
    line-height: 1.16;
  }

  h1, .vl-h1 {
    font-size: 38px;
    font-weight: 300;
  }
  h2, .vl-h2 {
    font-size: 24px;
    font-weight: 300;
  }
  h3, .vl-h3 {
    font-size: 24px;
    font-weight: 200;
  }
  h4, .vl-h4 {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
  }
  p, li, .vl-p {
    font-size: 15px;
    line-height: 1.25;
    font-weight: 300;
    word-wrap: break-word;
  }

  .container {
    margin: 0 auto;
    max-width: 1140px;
  }

  color: $color-dark-white;

  .main {
    padding-top: 20px;
    background-color: $color-dark-white;

    .header-container {
      margin: 0 16px 8px;
      color: $color-primary-dark;
    }

    .product-container {
      padding: 36px 0;
      max-height: 500px;
      margin-bottom: 24px;
      color: $color-dark-white;
      background-color: $color-primary-dark;
      box-shadow: $shadow-2;
      position: relative;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .swipe {
        max-width: 80%;
        overflow-y: hidden;
        >div {
          overflow-y: hidden;
        }
      }

      .arrow {
        width: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        cursor:pointer;
      }
      .arrow:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      .arrow.right {
        transform: rotate(180deg)
      }

      .vl-swipe-pagination {
        max-width: 1024px;
      }

      .item-container {
        display: flex;
        flex-direction: row;

        height: 360px;
        max-width: 1024px;
        margin: 0 auto;

        color: $color-dark-white;
        background-color: $color-primary-dark;

        .image {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            max-height: 350px;
            max-width: 100%;
            box-shadow: $shadow-4;
            cursor: zoom-in;
          }
          iframe {
            border: 0;
            flex: 1;
            width: 100%;
          }
        }
        .description {
          flex: 1;
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          overflow: hidden;

          >div, pre {
            width: 100%
          }

          .mobile-image {
            display: none;
          }

          .header {
            display: flex;
            align-items: left;
            .logo {
              border-radius: 50%;
              background-color: white;
              width: 50px;
              height: 50px;
              min-width: 50px;
              min-height: 50px;
              background-size: 89%;
              background-position: 50%;
              background-repeat: no-repeat;
            }
            .logo-small {
              background-size: 79%;
            }
            .logo-very-small {
              background-size: 69%;
            }
            .text {
              margin-left: 20px;
            }
          }

          code {
            width: 100%;
            text-align: left;
            line-height: 38px;
            padding: 0 20px;
          }
        }
      }
      ::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
      }

    }

    .filter-container {
      display: flex;
      flex-direction: row;

      .nav-container {
        display: flex;
        height: 42px;
        line-height: 42px;
        flex-direction: column;
        margin-right: 24px;
        .vl-button {
          width: 150px;
          padding: 0 24px;
          margin-bottom: 10px;
        }
        .button-all {
          svg {
            display: none !important;
          }
        }
      }

      .cards-container {
        display: flex;
        flex-wrap: wrap;
        transition: all 1s;

        .card {
          width: 200px;
          height: 335px;
          cursor: pointer;
          margin: 0 24px 24px 0;
          color: $color-dark-white;
          background-color: $color-primary-dark;
          box-shadow: $shadow-2;
          transition: all 1s;

          display: flex;
          flex-direction: column;

          .header {
            display: flex;
            margin-top: 20px;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            min-height: 24px;

            .starred {
              clip-path: $chevron;
              -webkit-clip-path: $chevron;
              background-color: white;
              align-items: center;
              display: flex;
              width: 60px;
              right: -6px;
              position: relative;
              box-shadow: $shadow-4;
              svg {
                margin: 0 auto;
                color: $color-primary !important;
              }
            }
            .github {
              margin-left: 16px;
              a {
                color: $color-dark-white;
                cursor: pointer;
              }
            }
          }
          .media {
            margin: 20px;
            div {
              border-radius: 50%;
              background-color: white;
              height: 120px;
              width: 120px;
              display: flex;
              align-items: center;
              margin: 0 auto;
              img {
                width: 80px;
                height: 80px;
                margin: 0 auto;
              }
              .size-90 {
                width: 90px;
                height: 90px;
              }
            }
          }
          .text {
            padding: 16px;
            text-align: center;
            white-space: pre-line;
            .title {
              color: white;
              font-size: 18px;
              padding-bottom: 8px;
            }
            .description {
              font-size: 12px;
            }

          }
        }
        .card:hover {
          box-shadow: $shadow-5, inset 0 2px 10px rgba(0, 0, 0, 0.09), 0 2px 10px rgba(0,0,0,0.23);
          background-color: rgba(56, 56, 58, 0.92);

        }
      }

    }
  }

  @media (max-width: 860px) {
    .main {
      .header-container {
        height: 60px;
      }
      .product-container {
        max-height: initial;
        .item-container {
          height: initial;
          .image {
            display: none;
          }
          .description {
            margin: 0 auto;
            max-width: 444px;
            align-items: center;
            .mobile-image {
              display: flex;
              justify-content: center;
              margin: 10px 0;
              max-height: 280px;
              img {
                width: 100%;
              }
              iframe {
                width: 444px;
                border: 0;
                height: 280px;

              }
            }
            .flex-container {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .main {
      .product-container {
        .arrow {
          display: none;
        }
        .swipe {
          max-width: initial;
          .description {
            padding: 0 16px;
            .mobile-image {
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              padding: 8px 0;
            }
            code {
              white-space: normal
            }
          }
        }
      }
      .filter-container {
        .nav-container {
          .vl-button {
            width: initial;
            padding: 8px;
            span {
              display: none
            }
          }
          .button-all {
            svg {
              display: block !important;
            }
          }
        }
        .cards-container {
          flex-direction: row;
          overflow-x: scroll;
          flex-wrap: nowrap;
          .card {
            min-width: 200px;
          }
        }
      }
    }
  }

}